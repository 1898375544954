import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { applyConstants } from './constants';
import { typeCastResponse } from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  verticalizationQuestions: [],
});

const apply = (state = initialState, action) => {
  switch (action.type) {
    // Loading
    case applyConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);

    case applyConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case applyConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case applyConstants.GET_VERTICALIZATION_QUESTIONS:
      return state
        .set(
          'verticalizationQuestions',
          typeCastResponse(action, 'data', 'array', []),
        )
        .set(
          'jobFunctionTitle',
          typeCastResponse(action, 'title', 'object', {}),
        );

    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
};

export default apply;
