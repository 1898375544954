import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import browser from 'browser-detect';
import { Portal } from 'react-portal';
import './pr-popup.scss';
import PrXMarkComponent from '../pr-x-mark';
import { strictValidString } from '../../../utils/commonUtils';
// Body

const PopupBody = ({ children, flex, className = '', scroll }) => {
  return flex ? (
    <div className={`content flex ${className}`}>{children}</div>
  ) : (
    <div className={`content main ${className}`} onScroll={scroll}>
      {children}
    </div>
  );
};

PopupBody.propTypes = {
  children: PropTypes.any,
  flex: PropTypes.bool,
  scroll: PropTypes.func,
};

PopupBody.defaultProps = {
  flex: false,
  scroll: undefined,
  children: null,
};

// Footer

const PopupFooter = ({ type, children, align, className }) => {
  return (
    <div
      className={`content footer ${align} ${className} ${type ? 'type' : ''}`}
    >
      {children}
    </div>
  );
};

PopupFooter.propTypes = {
  children: PropTypes.any,
  align: PropTypes.string,
};

PopupFooter.defaultProps = {
  align: 'center',
  children: null,
};

// Header

const PopupHeader = ({ children, close, toggle, action, customClassName }) => {
  return (
    <Fragment>
      {close && (
        <PrXMarkComponent
          onClick={(e) => toggle && toggle(e)}
        ></PrXMarkComponent>
      )}
      <div
        key="popupheader"
        className={`content header ${customClassName ? customClassName : ''}`}
      >
        {strictValidString(action) && action === 'success' && (
          <div className="icon">
            <div className="warning"></div>
            <div className="success">
              <div className="check-mark"></div>
            </div>
          </div>
        )}
        {strictValidString(action) && action === 'warning' && (
          <div className="icon">
            <div className="warning"></div>
          </div>
        )}
        {strictValidString(action) && action === 'error' && (
          <div className="icon">
            <div className="error"></div>
          </div>
        )}
        {children}
      </div>
    </Fragment>
  );
};

PopupHeader.propTypes = {
  children: PropTypes.any,
  close: PropTypes.bool,
  toggle: PropTypes.func,
  action: PropTypes.string,
};

PopupHeader.defaultProps = {
  close: false,
  toggle: undefined,
  action: undefined,
  children: null,
};

// Main Popup

const Popup = ({
  scrollContent,
  children,
  isOpen,
  action,
  className: psClassName,
  popupRef,
  customClassName,
}) => {
  const browserInfo = browser();

  useEffect(() => {
    if (isOpen)
      document.getElementsByTagName('html')[0].classList.add('no-scroll');
    return () => {
      document.getElementsByTagName('html')[0].classList.remove('no-scroll');
    };
  }, []);

  return (
    <Portal>
      <div
        ref={popupRef}
        type={action}
        key="popup"
        className={`${scrollContent ? 'pr-popup-scroll' : 'pr-popup'} ${
          isOpen ? 'in-view' : ''
        } ${browserInfo.name} ${psClassName} ${
          customClassName ? customClassName : ''
        }`}
      >
        <div className="pr-popup-inner">{children}</div>
      </div>
    </Portal>
  );
};

Popup.propTypes = {
  scrollContent: PropTypes.bool,
  popupRef: PropTypes.element,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  action: PropTypes.string,
  className: PropTypes.string,
};

Popup.defaultProps = {
  scrollContent: false,
  popupRef: null,
  isOpen: false,
  action: undefined,
  className: '',
  children: null,
};

export { Popup, PopupHeader, PopupFooter, PopupBody };
