import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import store from 'store2';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthTokenFlag = !!store('SAS_CANDIDATE_TOKEN');
  const { computedMatch = {}, isExternalRoute = false } = rest;
  const { params = {} } = computedMatch;
  const { key } = params;
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthTokenFlag || isExternalRoute ? (
          <Component key={key} {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.shape(PropTypes.objectOf),
};

PublicRoute.defaultProps = {
  component: undefined,
};
