/* eslint-disable no-case-declarations */
import Immutable from 'immutable';
import store from 'store2';
import { authConstants } from './constants';
import { typeCastResponse } from '../../utils/commonUtils';
import { validCandidate } from '../../utils/siteSpecificCommonUtils';

const initialRawState = Immutable.fromJS({
  isLoadInner: false,
  isLoad: false,
  message: null,
  loadErr: null,
  // user: (validCandidate(store.get('user')) && store.get('user')) || null,
  sessionExpired: false,
  pathName: '',
});

const initialState = initialRawState.set(
  'user',
  (validCandidate(store.get('user')) && store.get('user')) || null,
);

export default function reducer(state = initialState, action) {
  const user = state.get('user');
  switch (action.type) {
    // Loading
    case authConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case authConstants.LOAD_INNER:
      return state.set('isLoadInner', true).set('loadErr', null);
    case authConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case authConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    // Assign user
    case authConstants.ASSIGN_USER:
      return state.set(
        'user',
        (validCandidate(store.get('user')) && action.user) || null,
      );
    // for public use only
    case authConstants.SET_PUBLIC_PROFILE:
      return state.set('user', typeCastResponse(action, 'user', 'object', {}));

    // Update domain list
    case authConstants.MSA_SIGNED:
      return state.set('user', {
        ...user,
        company: {
          ...user.company,
          msa_signed: true,
        },
      });
    // Update domain list
    case authConstants.UPDATE_DOMAIN:
      return state.set('user', {
        ...user,
        company: {
          ...user.company,
          domains: typeCastResponse(action, 'domainsList', 'array', []),
        },
      });
    case authConstants.UPDATE_COMPANY_PROFILE:
      const userDetail = state.get('user');
      return state.set('user', {
        ...userDetail,
        company: typeCastResponse(action, 'company', 'object', {}),
      });
    // Reset reducer
    case authConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null);
    case authConstants.FLUSH:
      return initialState
        .set('user', null)
        .set('SAS_CANDIDATE_TOKEN', null)
        .set('pathName', state.get('pathName'));
    case authConstants.SESSION_EXPIRED:
      return state.set('sessionExpired', action.sessionExpired);
    case authConstants.STORE_LAST_PATH:
      return state.set('pathName', action.pathName);
    case authConstants.USER_NOT_ALLOWED:
      return state
        .set('userNotAllowed', action.userNotAllowed)
        .set('userNotAllowedMsg', action.message);
    // Default
    default:
      return state;
  }
}
