import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { typeCastResponse } from '../../utils/commonUtils';
import { topMatchesConstants } from './constants';

const initialState = Immutable.fromJS({
  isLoad: true,
  loadErr: null,
  message: null,
  list: {
    rows: [],
    count: 0,
  },
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case topMatchesConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case topMatchesConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case topMatchesConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('loadErr', typeCastResponse(action, 'error'));
    // Loading notifications into list
    case topMatchesConstants.LOAD_LIST:
      return state.set('list', typeCastResponse(action, 'list', 'object', {}));
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location &&
        action.payload.location.pathname === '/'
      ) {
        return state;
      }
      return initialState;
    default:
      return state;
  }
}
