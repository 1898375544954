/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import {
  Dashboard,
  TimesheetList,
  CandidateProfile,
  CandidateDetails,
} from './loadableComponents';
import {
  Popup,
  PopupBody,
  PopupFooter,
  PopupHeader,
} from '../components/--primitives/pr-popup';
import PrButton from '../components/--primitives/pr-button';
import { authenticate, logout } from '../containers/auth/actions';
import Loading from '../components/--primitives/pr-loading';
import { isAuthenticated } from '../utils/siteSpecificCommonUtils';
import RealTimeNotificationMessages from '../containers/real-time-notication-message';
import { typeCastToKeyValueObject } from '../utils/commonUtils';
import { setError } from '../redux/actions';
import { messages } from '../language';

const Routes = ({
  sessionExpired,
  callAuthenticateAPI,
  callLogoutAPI,
  user,
  isLoad,
  dispatch,
}) => {
  const initialization = async () => {
    await callAuthenticateAPI();
  };

  useEffect(() => {
    if (!isAuthenticated(user)) {
      initialization();
    }
  }, []);
  
  return (
    <Fragment>
      <RealTimeNotificationMessages />
      {sessionExpired && (
        <Popup isOpen={sessionExpired} action="warning">
          <PopupHeader action="warning" />
          <PopupBody>
            <div className="content title">Session expired</div>
            <div className="content text">
              Your session has expired, please login to continue.
            </div>
          </PopupBody>
          <PopupFooter>
            <PrButton
              value="Go back to login"
              type="blue tall"
              onClick={() => callLogoutAPI()}
            />
          </PopupFooter>
        </Popup>
      )}
      {isLoad ? (
        <Loading />
      ) : (
        <Switch key="privateroutes">
          <Route exact path="/candidate-details" component={CandidateDetails} />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/profile" component={CandidateProfile} />
          <Route exact path="/timesheets" component={TimesheetList} />
          <Route
            exact
            path="/timesheets/:timesheet_id"
            component={TimesheetList}
          />
          <Route
            exact
            path="/feedback-notif/:jobID/:appointmentID"
            component={Dashboard}
          />
          <Route
            exact
            path="/interview-schedule/:activeTabValue"
            component={Dashboard}
          />
          <Route exact path="/top-matches" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route
            path="*"
            exact
            render={() => {
              if (isAuthenticated(user)) {
                dispatch(setError(messages.WRONG_URL));
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>
      )}
    </Fragment>
  );
};

Routes.propTypes = {
  callLogoutAPI: PropTypes.func.isRequired,
  callAuthenticateAPI: PropTypes.func.isRequired,
  user: PropTypes.shape(PropTypes.objectOf),
  dispatch: PropTypes.func.isRequired,
  sessionExpired: PropTypes.bool,
};

Routes.defaultProps = {
  user: {},
  sessionExpired: false,
};

const mapStateProps = (state) => ({
  user: typeCastToKeyValueObject(state.get('auth').get('user')),
  isLoad: state.get('auth').get('isLoad'),
  sessionExpired: typeCastToKeyValueObject(
    state.get('auth').get('sessionExpired'),
  ),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callAuthenticateAPI: (...params) => dispatch(authenticate(...params)),
  callLogoutAPI: (...params) => dispatch(logout(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(Routes);
