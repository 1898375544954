import Immutable from 'immutable';
// import { LOCATION_CHANGE } from 'connected-react-router';
import { jobDetailConstants } from './constants';
import { typeCastResponse } from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  loadErr: null,
  message: null,
  details: {},
  clientSlots: [],
  jobFunctionQuestions: [],
  jobVerticalizationQuestion: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case jobDetailConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case jobDetailConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case jobDetailConstants.SET_CLIENT_SLOTS:
      return state.set(
        'clientSlots',
        typeCastResponse(action, 'payload', 'array', []),
      );
    case jobDetailConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case jobDetailConstants.GET_JOB_DETAILS:
      return state.set(
        'details',
        typeCastResponse(action, 'payload', 'object', {}),
      );
    case jobDetailConstants.GET_QUESTIONS:
      return state
        .set('isLoad', false)
        .set('questions', typeCastResponse(action, 'data', 'array', []))
        .set('loadErr', typeCastResponse(action, 'error'));
    case jobDetailConstants.GET_JOB_FUNCTION_QUESTIONS:
      return state.set(
        'jobFunctionQuestions',
        typeCastResponse(action, 'data', 'array', []),
      );
    case jobDetailConstants.GET_JOB_VERTICALIZATION_DATA:
      return state.set(
        'jobVerticalizationQuestion',
        typeCastResponse(action, 'data', 'array', []),
      );
    default:
      return state;
  }
}
