import moment from 'moment-timezone';
import {
  formatNumber,
  strictValidArray,
  strictValidArrayWithLength,
  strictValidArrayWithMinLength,
  strictValidNumber,
  strictValidObjectWithKeys,
  strictValidString,
  validObjectWithParameterKeys,
} from './commonUtils';
import { DEFAULT_TIMEZONE, USER_ROLES } from './siteSpecificConstants';

/**
 * If valid logged in user
 * @param user: object
 */
export const validUser = (user) =>
  validObjectWithParameterKeys(user, [
    '_id',
    'status',
    'name',
    'first_name',
    'email',
    'role',
  ]) &&
  strictValidString(user.name) &&
  strictValidString(user.first_name) &&
  strictValidString(user.email) &&
  strictValidNumber(user.status) &&
  strictValidString(user.role);

/**
 * If valid logged in user
 * @param user: object
 */
export const validLoggedInUser = (user) =>
  validUser(user) && !!user.status && strictValidString(user.role);

/**
 * Checks if a valid company user
 * @param user: object
 */
export const validCompany = (user) =>
  validLoggedInUser(user) &&
  [USER_ROLES.CLIENT_ADMIN, USER_ROLES.CLIENT_USER].indexOf(user.role) > -1;

/**
 * Checks if a valid job
 * @param job: object
 */
export const validJob = (job) =>
  validObjectWithParameterKeys(job, ['_id', 'status']);

/**
 * Checks if a valid candidate user
 * @param user: object
 */
export const validCandidate = (user) =>
  validLoggedInUser(user) && [USER_ROLES.CANDIDATE].indexOf(user.role) > -1;
/**
 * authenticated from API and update the it store
 * @param user: object
 */
export const isAuthenticated = (user) =>
  validUser(user) &&
  validObjectWithParameterKeys(user, ['isAuthenticated']) &&
  user.isAuthenticated;

/**
 * Checks if a valid pending job
 * @param job: object
 */
export const validPendingJob = (job) =>
  validObjectWithParameterKeys(job, ['is_first']) &&
  job.is_first &&
  job.status === 2;

/**
 * Get job status
 * @param {*} status: number
 */
export const getJobStatus = (status) => {
  switch (status) {
    case '1':
      return 'Invited';
    case '31':
      return 'Calendaring';
    case '6':
      return 'Interviewed';
    case '4':
      return 'Offered';
    case '22':
      return 'Declined';
    case '21':
      return 'Passed On';
    default:
      return '';
  }
};
/**
 * If valid invited company user
 * @param user: object
 */
export const validInvitedCompanyUser = (user) =>
  validObjectWithParameterKeys(user, [
    '_id',
    'email',
    'status',
    'role',
    'activation_key',
  ]) &&
  strictValidString(user.email) &&
  strictValidNumber(user.status) &&
  strictValidString(user.role) &&
  [USER_ROLES.CLIENT_ADMIN, USER_ROLES.CLIENT_USER].indexOf(user.role) > -1 &&
  strictValidString(user.activation_key);

/**
 * Get minimum & maximum string
 * @param min: number
 * @param max: number
 * @param assistedText: string
 */
export const getMinMaxString = (
  min,
  max,
  assistedText,
  preAssistedText,
  formatting = false,
) =>
  (strictValidNumber(min) &&
    strictValidNumber(max) &&
    strictValidString(assistedText) &&
    ((strictValidString(preAssistedText) &&
      ((max &&
        min < max &&
        `${preAssistedText}${
          formatting ? formatNumber(min) : min
        } - ${preAssistedText}${
          formatting ? formatNumber(max) : max
        } ${assistedText}`) ||
        (min &&
          `> ${preAssistedText}${
            formatting ? formatNumber(min) : min
          } ${assistedText}`))) ||
      (!strictValidString(preAssistedText) &&
        ((max &&
          min < max &&
          `${formatting ? formatNumber(min) : min} - ${
            formatting ? formatNumber(max) : max
          } ${assistedText}`) ||
          (min &&
            `> ${formatting ? formatNumber(min) : min} ${assistedText}`))))) ||
  '';

export const getAddressStateObj = (location) => {
  const coordinates = validObjectWithParameterKeys(location, ['loc'])
    ? location.loc.coordinates
    : null;
  const locationCoordinates = coordinates
    ? { lat: coordinates[1], lng: coordinates[0] }
    : null;
  return {
    location_coordinates: locationCoordinates,
    timezone: validObjectWithParameterKeys(location, ['timezone'])
      ? location.timezone
      : null,
    address_2: validObjectWithParameterKeys(location, ['address_2'])
      ? location.address_2
      : null,
    location: validObjectWithParameterKeys(location, ['address'])
      ? location.address
      : null,
    zipcode: validObjectWithParameterKeys(location, ['zipcode'])
      ? location.zipcode
      : null,
    city: validObjectWithParameterKeys(location, ['city'])
      ? location.city
      : null,
    state: validObjectWithParameterKeys(location, ['state'])
      ? location.state
      : null,
    county: validObjectWithParameterKeys(location, ['county'])
      ? location.county
      : null,
    country: validObjectWithParameterKeys(location, ['country'])
      ? location.country
      : null,
    location_id: validObjectWithParameterKeys(location, ['_id'])
      ? location._id
      : null,
  };
};

/**
 * Checks if a valid category
 * @param category: object
 */
export const validCategory = (category) =>
  validObjectWithParameterKeys(category, ['_id', 'title', 'icon', 'titles']) &&
  strictValidString(category.title) &&
  strictValidString(category.icon) &&
  strictValidArray(category.titles);

/**
 * Checks if a valid education
 * @param education: object
 */
export const validEducation = (education) =>
  validObjectWithParameterKeys(education, ['_id', 'title']) &&
  strictValidString(education.title);

export const getUTCDate = (date) =>
  date === null ? null : moment(date).format('YYYY-MM-DD');

/**
 * Checks if a valid role
 * @param role: object
 */
export const validRole = (role) =>
  validObjectWithParameterKeys(role, ['_id', 'title', 'duties']) &&
  strictValidString(role.title) &&
  strictValidArray(role.duties);

/**
 * Checks if a valid industry
 * @param industry: object
 */
export const validIndustry = (industry) =>
  validObjectWithParameterKeys(industry, ['_id', 'title']) &&
  strictValidString(industry.title);
/**
 * Checks if a valid duty
 * @param duty: object
 */
export const validDuty = (duty) =>
  validObjectWithParameterKeys(duty, ['_id', 'title']) &&
  strictValidString(duty.title);

export const getTimezone = (timezone) =>
  (strictValidString(timezone) && timezone) || DEFAULT_TIMEZONE;

export const getTimezoneStr = (timezone) =>
  moment.tz(getTimezone(timezone)).format('z');

export const formatTimezoneStr = (timezone) =>
  getTimezoneStr(timezone).replace('DT', 'ST');

/**
 * Validate keyword
 * @param keyWord
 */
export const validKeyWord = (keyWord) =>
  validObjectWithParameterKeys(keyWord, ['_id', 'title']);

export const getBillableMinutes = (dt1, dt2, mealBreak = 0, sickLeave = 0) => {
  const enteredMinutes = moment
    .duration(moment(dt2, 'HH:mm a').diff(moment(dt1, 'HH:mm a')))
    .asMinutes();

  let billableMinutes;

  if (enteredMinutes > 0 || enteredMinutes < 0) {
    billableMinutes =
      enteredMinutes - Number(mealBreak) + Number(sickLeave) || 0;
    return billableMinutes;
  }

  billableMinutes = Number(sickLeave) || 0;
  return billableMinutes;
};

const convertToHoursAndMinutes = (value) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  return { hours, minutes };
};

export const getDailyBillableHours = (dt1, dt2, mealBreak, sickLeave) => {
  const billableMinutes = getBillableMinutes(dt1, dt2, mealBreak, sickLeave);
  if (billableMinutes < 0) return 'Negative value!';

  const { hours, minutes } = convertToHoursAndMinutes(billableMinutes);
  const formattedMinutes =
    minutes.toString().length > 1 ? minutes : `0${minutes}`;
  return `${hours}:${formattedMinutes}`;
};

export const getTotalBillableHours = (values) => {
  let totalMinutes = 0;
  values.forEach((item, index) => {
    totalMinutes += getBillableMinutes(
      item.in_time,
      item.out_time,
      item.meal_break_minutes,
      item.sick_leave_minutes,
    );
  });

  const { hours, minutes } = convertToHoursAndMinutes(totalMinutes);
  const formattedMinutes =
    minutes.toString().length > 1 ? minutes : `0${minutes}`;
  return `${hours}:${formattedMinutes}` || `0:00`;
};

// This function convert mins To hrs (Hrs:Mins) even given parameter is negative,
// e.g (m = -210) -> (-3:30)
export const convertMinsToHrsMins = (m) => {
  let h = Math.floor(m / 60);
  h += h < 0 ? 1 : 0;
  let m2 = Math.round(Math.abs(m % 60));
  m2 = m2 < 10 ? `0${m2}` : m2;
  return `${h}:${m2}`;
};

export const minutesToHours = (value) => {
  const hours = value / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const formattedMinutes =
    rminutes.toString().length > 1 ? rminutes : `0${rminutes}`;
  return `${rhours}:${formattedMinutes}`;
};

export const getDayNameFromIndex = (index) => {
  switch (index) {
    case 0:
      return 'Mon';
    case 1:
      return 'Tue';
    case 2:
      return 'Wed';
    case 3:
      return 'Thu';
    case 4:
      return 'Fri';
    case 5:
      return 'Sat';
    case 6:
      return 'Sun';
    default:
      return '';
  }
};

export const getIndexByNumber = (day) => {
  switch (day) {
    case 'Mon':
      return 0;
    case 'Tue':
      return 1;
    case 'Wed':
      return 2;
    case 'Thu':
      return 3;
    case 'Fri':
      return 4;
    case 'Sat':
      return 5;
    case 'Sun':
      return 6;
    default:
      return '';
  }
};

/**
 * Get job status for candidate side
 * @param {*} status: number
 */
export const getCandidateJobStatus = (status, subStatus, rejectedBy) => {
  let statusText = '';
  switch (status) {
    case 1:
      statusText = 'Invited';
      break;
    case 2:
      switch (rejectedBy) {
        case 'Company':
          if (subStatus === 5) statusText = 'Offer Declined';
          else statusText = 'Passed On';
          break;
        case 'Candidate':
          statusText = 'Declined';
          break;
        case 'System':
          statusText = 'Passed On';
          break;
        default:
          break;
      }
      break;
    case 3:
      switch (subStatus) {
        case 0:
          statusText = 'Scheduling';
          break;
        case 1:
          statusText = 'Scheduled';
          break;
        case 2:
          statusText = 'Conflict';
          break;
        case 3:
          statusText = 'Interviewed';
          break;
        default:
          break;
      }
      break;
    case 4:
      statusText = 'Offered';
      break;
    case 5:
      statusText = 'On Hold';
      break;
    case 6:
      statusText = 'Interviewed';
      break;
    case 7:
      statusText = 'Accepted';
      break;
    default:
      break;
  }

  return statusText || '';
};

/**
 * Check if incomplete newly created candidate profile by admin portal
 * @param candidate: object
 */
export const checkIfValidCandidateProfile = (candidate) => {
  let isCompleteFlag = false;
  if (
    validCandidate(candidate) &&
    validObjectWithParameterKeys(candidate, ['_id', 'profile']) &&
    strictValidObjectWithKeys(candidate.profile)
  ) {
    const {
      job_functions = [],
      filters = [],
      job_type_1 = [],
      job_type_2 = [],
      compensation_max,
      compensation_min,
      proximity,
      education,
      start_date,
      preferred_job_locations,
    } = candidate.profile;
    const {
      city,
      state,
      location_coordinates = {},
    } = strictValidArrayWithLength(preferred_job_locations)
      ? preferred_job_locations[0]
      : {};
    const { coordinates = [] } = location_coordinates;
    if (
      strictValidArrayWithLength(job_functions) &&
      strictValidArrayWithLength(filters) &&
      strictValidArrayWithMinLength(coordinates, 2) &&
      strictValidArrayWithLength(job_type_1) &&
      strictValidArrayWithLength(job_type_2) &&
      strictValidNumber(compensation_max) &&
      strictValidNumber(compensation_min) &&
      strictValidNumber(proximity) &&
      strictValidString(city) &&
      strictValidString(state) &&
      strictValidString(education) &&
      strictValidString(start_date)
    ) {
      isCompleteFlag = true;
    }
  }
  return isCompleteFlag;
};

/**
 * Checks if a valid profile
 * @param job: object
 */
export const validProfile = (profile) =>
  validObjectWithParameterKeys(profile, [
    'filters',
    'proximity',
    'is_remote',
    'is_relocation',
    'location_coordinates',
    'is_pending',
  ]) &&
  strictValidArrayWithLength(profile.filters) &&
  strictValidNumber(profile.proximity) &&
  validObjectWithParameterKeys(profile, ['location_coordinates']) &&
  validObjectWithParameterKeys(profile.location_coordinates, ['coordinates']) &&
  strictValidArrayWithMinLength(profile.location_coordinates.coordinates, 2);

/**
 * Checks if a valid pending profile
 * @param job: object
 */
export const validPendingProfile = (profile) => profile.is_pending;

/**
 * Blurrs a given string
 * @param str: string
 */
export const blurrString = (str) => {
  let res = '';
  if (strictValidString(str)) {
    res = str
      .split(' ')
      .map((v) =>
        v.length
          ? `${v.charAt(0)}${v
              .substr(1)
              .split('')
              .map((i) => '*')
              .join('')}`
          : '',
      )
      .join(' ');
  }
  return res;
};

export const getRemoteLOVs = (id) => {
  switch (id) {
    case 1:
      return 'Yes, I only want to work remotely';
    case 2:
      return "Yes, I'm open to remote work";
    case 0:
      return "No, I'm not open to remote work";
    default:
      return '';
  }
};

export const getJobRemoteLOVs = (id) => {
  switch (id) {
    case 0:
      return 'No';
    case 1:
      return '100% remote';
    case 2:
      return 'Remote optional';
    case 3:
      return 'Temporarily remote due to special circumstance';
    default:
      return '';
  }
};

export const JOB_STATUS_TITLE = {
  0: 'InActive',
  1: 'Open',
  2: 'Draft',
  3: 'On Hold',
  4: 'Closed',
};
/**
 * Get actual job status
 * @param {*} status: number
 * By Neeraj kumar on 27th May 2021
 */
export const getActualJobStatus = (status) => {
  switch (status) {
    case 1:
      return 'Open';
    case 2:
      return 'Draft';
    case 3:
      return 'On Hold';
    case 4:
      return 'Closed';
    default:
      return '';
  }
};
const countBillPay = (val, billRate) =>
  strictValidNumber(val) && Number((val / (1 + billRate / 100)).toFixed(2));

export const getBillPayMinMaxString = (
  min,
  max,
  billPay,
  assistedText,
  preAssistedText,
  formatting = false,
) =>
  (strictValidNumber(min) &&
    strictValidNumber(max) &&
    strictValidString(assistedText) &&
    ((strictValidString(preAssistedText) &&
      ((max &&
        min < max &&
        `${preAssistedText}${
          formatting
            ? formatNumber(countBillPay(min, billPay))
            : countBillPay(min, billPay)
        } - ${preAssistedText}${
          formatting
            ? formatNumber(countBillPay(max, billPay))
            : countBillPay(max, billPay)
        } ${assistedText}`) ||
        (min &&
          `> ${preAssistedText}${
            formatting
              ? formatNumber(countBillPay(min, billPay))
              : countBillPay(min, billPay)
          } ${assistedText}`))) ||
      (!strictValidString(preAssistedText) &&
        ((max &&
          min < max &&
          `${
            formatting
              ? formatNumber(countBillPay(min, billPay))
              : countBillPay(min, billPay)
          } - ${
            formatting
              ? formatNumber(countBillPay(max, billPay))
              : countBillPay(max, billPay)
          } ${assistedText}`) ||
          (min &&
            `> ${
              formatting
                ? formatNumber(countBillPay(min, billPay))
                : countBillPay(min, billPay)
            } ${assistedText}`))))) ||
  '';


export const randomNumber = () =>{
  const crypto = window.crypto || window.msCrypto;
  let array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
}