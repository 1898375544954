import store from 'store2';
import { push } from 'react-router-redux';
import { authConstants } from './constants';
import {
  socketConstants,
  errorConstants,
  googleAnalyticsConstants,
  configConstants,
} from '../../redux/constants';
import {
  validObjectWithParameterKeys,
  typeCastToString,
  typeCastToKeyValueObject,
  strictValidString,
} from '../../utils/commonUtils';
import {
  validLoggedInUser,
  validUser,
  validCandidate,
} from '../../utils/siteSpecificCommonUtils';
import { messages } from '../../language';
import history from '../../redux/lib/history';
/**
 * Authenticates user for logging in
 * Access role allowed - any
 * @param data: object containing email: string & password: string
 */
export const login = (data) => async (dispatch, _getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD_INNER });
  try {
    // Call api to authenticate user
    const res = await api.post('/users/login', { data });
    // If not valid response object
    if (
      !(validObjectWithParameterKeys(res, ['data']) && validCandidate(res.data))
    ) {
      await dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    if (validObjectWithParameterKeys(res.data, ['token'])) {
      store({
        SAS_CANDIDATE_TOKEN: typeCastToString(res.data.token),
        user: res.data,
      });
    }
    // Else if valid response continue
    await dispatch({
      type: authConstants.ASSIGN_USER,
      user: { ...res.data, isAuthenticated: true },
    });
    store.remove('isApplied');
    dispatch({ type: socketConstants.JOIN, _id: res.data._id });
    dispatch({ type: googleAnalyticsConstants.CONNECT, _id: res.data._id });
    await dispatch({ type: authConstants.LOAD_SUCCESS });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: strictValidString(error) ? error : error.message,
      status: strictValidString(error) ? null : error.status,
    });
    return false;
  }
};

/**
 * To load users account details
 * Access role allowed - any
 * @param forced: boolean
 */
export const authenticate = () => async (dispatch, getState, api) => {
  dispatch({ type: authConstants.LOAD });
  try {
    // Call api to fetch user data
    const res = await api.get('/users/authenticate');
    // If not valid response object
    if (
      !(validObjectWithParameterKeys(res, ['data']) && validCandidate(res.data))
    ) {
      return false;
    }
    // Else if valid response continue
    await dispatch({
      type: authConstants.ASSIGN_USER,
      user: { ...res.data, isAuthenticated: true },
    });
    dispatch({ type: socketConstants.JOIN, _id: res.data._id });
    dispatch({ type: googleAnalyticsConstants.CONNECT, _id: res.data._id });
    dispatch({ type: authConstants.LOAD_SUCCESS });
    return res.data;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({ type: authConstants.LOAD_FAIL });
    store({
      SAS_CANDIDATE_TOKEN: null,
      user: null,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      err,
    });
    return false;
  }
};

/**
 * To reset message fields in reducer
 */
export const authResetMessage = () => {
  return (dispatch) => dispatch({ type: authConstants.RESET_MESSAGE });
};

/**
 * Sends reset password email to submitted email address
 * Access role allowed - any
 * @param data: object containing email: string
 */
export const forgotPassword = (data) => async (dispatch, _getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD_INNER });
  try {
    // Call api to send reset password email
    const res = await api.post('/users/forgot-password', { data });
    dispatch({
      type: authConstants.LOAD_SUCCESS,
      message: typeCastToString(res.message),
    });
    return { isResponse: true };
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
    });
    return { isResponse: false, isError: true, msg: error };
  }
};

export const storeLastPathName = (pathName) => async (dispatch) => {
  dispatch({
    type: authConstants.STORE_LAST_PATH,
    pathName,
  });
};

/**
 * To set sessionExpired as true
 * Access role allowed - any
 * @param error: string
 */
export const validateSession = (status) => async (dispatch, getState) => {
  // Get user from reducer
  const user = typeCastToKeyValueObject(getState().get('auth').get('user'));
  if (validLoggedInUser(user) && status === 403) {
    dispatch({
      type: authConstants.SESSION_EXPIRED,
      sessionExpired: true,
    });
  }

  const { location: { pathname = '' } = {} } = history;
  dispatch(storeLastPathName(pathname));
};

/**
 * To validate user
 * Access role allowed - any
 * @param data: object containing key: string
 * @param type: string type of user
 */
export const validateUser = (data, type) => async (dispatch, getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call post session api to verify
    const res = await api.post('/users/validate', { data });
    let isValidData = true;
    if (res.data && type === 'invitedCompanyUser') {
      isValidData =
        res.data.hasOwnProperty('_id') &&
        res.data.hasOwnProperty('first_name') &&
        res.data.hasOwnProperty('status') &&
        res.data.hasOwnProperty('name') &&
        res.data.hasOwnProperty('email') &&
        res.data.hasOwnProperty('role');
    } else if (type !== 'invitedCompanyUser') {
      isValidData = validUser(res.data);
    }
    if (!isValidData) {
      dispatch({
        type: authConstants.LOAD_FAIL,
      });
      dispatch({
        type: errorConstants.SET_ERROR,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    await dispatch({ type: authConstants.LOAD_SUCCESS });
    return res.data;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
  }
};

/**
 * To reset password of user
 * Access role allowed - any
 * @param data: object containing key: string
 */
export const resetPassword = (data) => async (dispatch, _getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD });
  try {
    // Call post session api to reset password
    const res = await api.post('/users/reset-password', { data });
    // Else if valid response continue
    dispatch({
      type: authConstants.LOAD_SUCCESS,
      message: typeCastToString(res.message),
    });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    return false;
  }
};

/**
 * To activate user
 * Access role allowed - any
 * @param data: object containing key: string
 */
export const activateUser = (data) => async (dispatch, _getState, api) => {
  // Start loading
  dispatch({ type: authConstants.LOAD_INNER });
  try {
    // Call post session api to set password
    const res = await api.post('/users/activate', { data });
    const validResponseFlag =
      validObjectWithParameterKeys(res, ['data']) &&
      validObjectWithParameterKeys(res.data, ['user']) &&
      validLoggedInUser(res.data.user);
    // If not valid response object
    if (!validResponseFlag) {
      dispatch({
        type: authConstants.LOAD_FAIL,
        error: messages.DEFAULT_ERROR_MESSAGE,
      });
      return false;
    }
    // Else if valid response continue
    dispatch({
      type: authConstants.ASSIGN_USER,
      user: res.data.user,
    });
    if (validObjectWithParameterKeys(res.data.user, ['token'])) {
      store({
        SAS_CANDIDATE_TOKEN: typeCastToString(res.data.user.token),
        user: res.data.user,
      });
    }
    dispatch({ type: authConstants.LOAD_SUCCESS });
    return true;
  } catch (error) {
    // If an error occurs, set error field
    dispatch({
      type: authConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error,
    });
    return false;
  }
};

/**
 * To logout user, this flushes or empties all browser states
 * Access role allowed - logged in user of any role
 */
export const logout = () => async (dispatch, getState) => {
  if (store.get('rememberMe')) {
    const userDeatil = {
      email: store('email'),
      password: store('password'),
      rememberMe: store('rememberMe'),
    };
    store(false);
    store(userDeatil);
  } else {
    store(false);
  }
  // Socket disconnection
  dispatch({ type: socketConstants.DISCONNECT });
  // Flush this reducer on condition based
  await dispatch({ type: authConstants.FLUSH });
  const tenantConfig = typeCastToKeyValueObject(
    getState().get('config').get('tenantConfig'),
  );
  // empty the Redux store
  dispatch({ type: 'EMPTY_STORE' });
  dispatch({
    type: configConstants.TENANT_CONFIG,
    data: tenantConfig,
  });
  // Redirect user
  if (!strictValidString(store.get('SAS_CANDIDATE_TOKEN'))) dispatch(push('/'));
};
