export const profileConstants = {
  LOAD: 'profile/LOAD',
  EDIT_LOAD: 'profile/EDIT_LOAD',
  LOAD_SUCCESS: 'profile/LOAD_SUCCESS',
  LOAD_FAIL: 'profile/LOAD_FAIL',
  GET_DETAIL: 'profile/GET_DETAIL',
  JOB_DETAIL: 'profile/JOB_DETAIL',
  // GET_STATUS: 'profile/GET_STATUS',
  ASSIGN_USER: 'profile/ASSIGN_USER',
  GET_QUESTIONS: 'profile/GET_QUESTIONS',
  GET_DEMOGRAPHIC_QUESTIONS: 'profile/GET_DEMOGRAPHIC_QUESTIONS',
  GET_SKILLS: 'profile/GET_SKILLS',
  GET_VERTICALIZATION_DATA: 'profile/GET_VERTICALIZATION_DATA',
  LOAD_SKILLS: 'profile/LOAD_SKILLS',
  LOAD_STOP_SKILLS: 'profile/LOAD_STOP_SKILLS',
};
