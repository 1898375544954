import React from 'react';
import Loadable from '@loadable/component';
import Loading from '../components/--primitives/pr-loading';

/**
 * Pre Login modules
 */
export const Login = Loadable(() => import('../containers/auth/login'), {
  fallback: <Loading />,
});

export const Apply = Loadable(() => import('../containers/apply'), {
  fallback: <Loading />,
});

export const ResetPassword = Loadable(
  () => import('../containers/auth/reset-password'),
  {
    fallback: <Loading />,
  },
);

/**
 * Account activation Module
 */
export const ActivateAccountScreen = Loadable(
  () => import('../containers/auth/account-activation'),
  {
    fallback: <Loading />,
  },
);

export const CandidateDetails = Loadable(
  () => import('../containers/candidate-details'),
  {
    fallback: <Loading />,
  },
);

/**
 * Candidate Profile
 */
export const CandidateProfile = Loadable(
  () => import('../containers/profile'),
  {
    fallback: <Loading />,
  },
);

export const ViewJobDetails = Loadable(
  () => import('../containers/external-requests/view-job-details'),
  {
    fallback: <Loading />,
  },
);

export const PublicJobDetails = Loadable(
  () => import('../containers/external-requests/public-job-details'),
  {
    fallback: <Loading />,
  },
);

export const ApplyFromThirdParty = Loadable(
  () => import('../containers/external-requests/apply-job'),
  {
    fallback: <Loading />,
  },
);

/**
 * Interview Scheduling Process
 */
export const InterviewFeedback = Loadable(
  () => import('../containers/external-requests/Interview-feedback'),
  {
    fallback: <Loading />,
  },
);

/**
 * Dashboard
 */
export const Dashboard = Loadable(() => import('../containers/dashboard'), {
  fallback: <Loading />,
});

export const TopMatches = Loadable(() => import('../containers/top-matches'), {
  fallback: <Loading />,
});

/**
 * Timesheet List View
 */
export const TimesheetList = Loadable(() => import('../containers/timesheet'), {
  fallback: <Loading />,
});

export const PageError = Loadable(() => import('../containers/404-not-found'), {
  fallback: <Loading />,
});
