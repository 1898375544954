export const jobDetailConstants = {
  LOAD: 'jobDetailConstants/LOAD',
  LOAD_SUCCESS: 'jobDetailConstants/LOAD_SUCCESS',
  LOAD_FAIL: 'jobDetailConstants/LOAD_FAIL',
  GET_JOB_DETAILS: 'jobDetailConstants/GET_JOB_DETAILS',
  GET_CLIENT_SLOTS: 'jobDetailConstants/GET_CLIENT_SLOTS',
  SET_CLIENT_SLOTS: 'jobDetailConstants/SET_CLIENT_SLOTS',
  CLIENT_SLOTS_FAILURE: 'jobDetailConstants/CLIENT_SLOTS_FAILURE',
  GET_JOB_FUNCTION_QUESTIONS: 'jobDetailConstants/GET_JOB_FUNCTION_QUESTIONS',
  GET_JOB_VERTICALIZATION_DATA:
    'jobDetailConstants/GET_JOB_VERTICALIZATION_DATA',
  SET_APPOINTMENT: 'jobDetailConstants/SET_APPOINTMENT',
  GET_QUESTIONS: 'jobDetailConstants/GET_QUESTIONS',
};
