import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import store from 'store2';
import { connect } from 'react-redux';
import Routes from './routes';
import { storeLastPathName } from '../containers/auth/actions';

const PrivateRoute = ({ component: Component, dispatch, ...rest }) => {
  const isAuthTokenFlag = !!store('SAS_CANDIDATE_TOKEN');
  const { location: { pathname } = {} } = rest;
  if (!isAuthTokenFlag && pathname !== '/') {
    dispatch(storeLastPathName(pathname));
  }
  return (
    <Route
      {...rest}
      render={() =>
        isAuthTokenFlag ? (
          <Routes key={pathname} {...rest} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.shape(PropTypes.element),
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape(PropTypes.objectOf),
};

PrivateRoute.defaultProps = {
  component: undefined,
  location: {},
};

export default connect()(PrivateRoute);
