export const timesheetConstants = {
  LOAD: 'timesheet/LOAD',
  LOAD_SUCCESS: 'timesheet/LOAD_SUCCESS',
  LOAD_FAIL: 'timesheet/LOAD_FAIL',
  ADD_DETAIL_PROCESSING: 'timesheet/ADD_DETAIL_PROCESSING',
  ADD_DETAIL_FAILURE: 'timesheet/ADD_DETAIL_FAILURE',
  LAST_SUBMITTED_PROCESSING: 'timesheet/LAST_SUBMITTED_PROCESSING',
  LAST_SUBMITTED_SUCCESS: 'timesheet/LAST_SUBMITTED_SUCCESS',
  LAST_SUBMITTED_FAILURE: 'timesheet/LAST_SUBMITTED_FAILURE',
  SET_PREVIOUS_TIMESHEET_DETAILS: 'timesheet/SET_PREVIOUS_TIMESHEET_DETAILS',

  LOAD_LIST: 'timesheet/LOAD_LIST',
  GET_DETAILS: 'timesheet/GET_DETAILS',
};
