import app from './app';
import error from './error';
import config from './config';
import auth from '../../containers/auth/reducers';
import notifications from '../../containers/notifications/reducers';
import settings from './settings';
import timesheet from '../../containers/timesheet/reducers';
import dashboard from '../../containers/dashboard/reducers';
import matches from '../../containers/top-matches/reducer';
import profile from '../../containers/profile/reducers';
import apply from '../../containers/apply/reducer';
import interviewSchedule from '../../containers/interview-schedule/reducer';
import jobDetails from '../../containers/job-detail/reducer';

export default {
  app,
  error,
  auth,
  dashboard,
  profile,
  notifications,
  settings,
  timesheet,
  matches,
  interviewSchedule,
  jobDetails,
  apply,
  config,
};
