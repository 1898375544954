import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { profileConstants } from './constants';
import { typeCastResponse } from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoad: false,
  isEditLoad: false,
  isSkillLoad: false,
  loadErr: null,
  detail: {},
  questions: [],
  // status: {},
  skills: [],
  verticalizationData: [],
  demographic_question: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case profileConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case profileConstants.EDIT_LOAD:
      return state.set('isEditLoad', true).set('loadErr', null);
    case profileConstants.DETAILS:
      return state.set('details', action.payLoad);
    case profileConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case profileConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isEditLoad', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    case profileConstants.GET_DETAIL:
      return state
        .set('detail', typeCastResponse(action, 'detail', 'object', {}))
        .set('isEditLoad', false)
        .set('loadErr', null);
    case profileConstants.GET_QUESTIONS:
      return state
        .set('questions', typeCastResponse(action, 'data', 'array', []))
        .set('savedType', action.savedType);
    case profileConstants.GET_DEMOGRAPHIC_QUESTIONS:
      return state.set(
        'demographic_question',
        typeCastResponse(action, 'data', 'array', []),
      );
    // case profileConstants.GET_STATUS:
    //   return state.set(
    //     'status',
    //     typeCastResponse(action, 'data', 'object', {}),
    //   );
    case profileConstants.GET_SKILLS:
      return state.set('skills', typeCastResponse(action, 'data', 'array', []));
    case profileConstants.LOAD_SKILLS:
      return state.set('isSkillLoad', true).set('loadErr', null);
    case profileConstants.LOAD_STOP_SKILLS:
      return state.set('isSkillLoad', false).set('loadErr', null);
    case profileConstants.GET_VERTICALIZATION_DATA:
      return state.set(
        'verticalizationData',
        typeCastResponse(action, 'data', 'array', []),
      );
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
}
