import { settingsConstants } from '../constants/settings-constants';
import {
  // validObjectWithParameterKeys,
  strictValidArrayWithLength,
  removeInValidKeyValuePairs,
  validObjectWithParameterKeys,
} from '../../utils/commonUtils';
import { messages } from '../../language';
import { errorConstants } from '../constants';
/**
 * To load settings object which contain jobFunctions <Array>
 * Access role allowed - any
 */
export const loadSettings = (parm) => async (dispatch, getState, api) => {
  let data = parm;
  const settingData = removeInValidKeyValuePairs(
    getState().get('settings').toJS(),
    true,
  );
  if (!strictValidArrayWithLength(data)) {
    data = [
      'educations',
      'benefits',
      'companycultures',
      'candidate_hear_about_us',
    ];
  }
  // parseKeys is object to map querystring and saved keys in redux
  const parseKeys = {
    jobfunctions: 'jobFunctions',
    companycultures: 'cultures',
  };
  const settingKeys = data.map((v) => {
    if (validObjectWithParameterKeys(parseKeys, [v])) {
      return parseKeys[v];
    }
    return v;
  });
  if (validObjectWithParameterKeys(settingData, settingKeys)) {
    return settingData;
  }
  dispatch({
    type: settingsConstants.LOAD,
  });
  try {
    // Get job functions from api
    // let postURL =
    //   '/settings?select=jobfunctions,industries,educations,benefits,companycultures,regions,titles';
    const postURL = `/settings?select=${data.join(',')}`;
    const res = await api.get(postURL);
    const {
      job_functions: jobFunctions,
      industries,
      educations,
      benefits,
      company_cultures: cultures,
      timezones,
      candidate_hear_about_us,
      resignation_notice,
      reason_for_leaving,
      candidate_decline_reason,
      company_size,
      titles,
      timesheet_missed_break_reason,
      msa_lovs,
    } = res.data;
    dispatch({
      type: settingsConstants.LOAD_SETTINGS,
      jobFunctions,
      industries,
      educations,
      benefits,
      cultures,
      timezones,
      candidate_hear_about_us,
      resignation_notice,
      reason_for_leaving,
      candidate_decline_reason,
      company_size,
      titles,
      timesheet_missed_break_reason,
      msaLovs: msa_lovs,
      ...settingData,
    });
    dispatch({
      type: settingsConstants.LOAD_SUCCESS,
    });
    return res.data;
  } catch (err) {
    // If an error occurs, set error field
    dispatch({
      type: settingsConstants.LOAD_FAIL,
    });
    dispatch({
      type: errorConstants.SET_ERROR,
      error: messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};

export const loadSettingsConfig = (parm) => async (dispatch, getState, api) => {
  const data = parm;
  try {
    const postURL = `/settings/config?select=${data.join(',')}`;
    return await api.get(postURL);
  } catch (err) {
    dispatch({
      type: errorConstants.SET_ERROR,
      error: messages.DEFAULT_ERROR_MESSAGE,
    });
    return false;
  }
};
