export const authConstants = {
  LOAD: 'auth/LOAD',
  LOAD_INNER: 'auth/LOAD_INNER',
  LOAD_SUCCESS: 'auth/LOAD_SUCCESS',
  LOAD_FAIL: 'auth/LOAD_FAIL',
  ASSIGN_USER: 'auth/ASSIGN_USER',
  SET_PUBLIC_PROFILE: 'auth/SET_PUBLIC_PROFILE',
  UPDATE_DOMAIN: 'auth/UPDATE_DOMAIN',
  MSA_SIGNED: 'MSA_SIGNED',
  // Company Update Profile
  UPDATE_COMPANY_PROFILE: 'auth/UPDATE_COMPANY_PROFILE',
  // Reset reducer
  RESET_MESSAGE: 'auth/RESET_MESSAGE',
  SESSION_EXPIRED: 'auth/SESSION_EXPIRED',
  FLUSH: 'auth/FLUSH',
  STORE_LAST_PATH: 'auth/STORE_LAST_PATH',
  EMAIL_EXISTS: 'auth/EMAIL_EXISTS',
};
