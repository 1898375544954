export const USER_ROLES = {
  CANDIDATE: 'candidate',
};

export const PREPEND_LINKEDIN_VALUE = 'https://www.linkedin.com/in/';

export const CHECK_LINKED_IN_CASES = [
  'https://www.linkedin.com/in/',
  'https://www.linkedin.com/in/www.',
  'https',
  'http://www.linkedin.com/in/',
  'http',
  'www.linkedin.com/in/',
  'www.linkedin.com',
  'www.linkedin/',
  'www.linkedin',
  'www',
  'linkedin.com/in/',
  'linkedin.com',
  '.com',
  './',
  '/',
];

export const DATE_FORMAT_MDY = 'MM/DD/YYYY';

export const DATE_FORMAT_YMD = 'YYYY-MM-DD';
export const DATE_FORMAT_PRETTY_MDY = 'MMMM DD, YYYY';

export const DATE_FORMAT_DDDD = 'dddd';

export const DATE_FORMAT_DDDD_WITH_TIME = 'dddd hh:mm A';

export const DATE_FORMAT_HOUR = 'hh:mm A';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const DEFAULT_COMPANY_NAME = 'Closeloop Technologies';

export const JOB_STATUS = {
  IN_ACTIVE: 0,
  OPEN: 1,
  IN_PROGRESS: 2,
  ON_HOLD: 3,
  CLOSE: 4,
};
export const DEFAULT_PASSWORD_MIN_LENGTH = 8;

export const DEFAULT_PASSWORD_MAX_LENGTH = 20;

export const TIMESHEET_HOLIDAY_VERBIAGE = {
  CANDIDATE: {
    HEADER: 'Only enter hours that you actually worked.',
    IS_PTO:
      'You are eligible for holiday PTO, which will be added to your paycheck accordingly.',
  },
};

export const contractDurationOptions = [
  { value: '1 Day', label: '1 Day' },
  { value: '1 Week', label: '1 Week' },
  { value: '1 Month', label: '1 Month' },
  { value: '3 Months', label: '3 Months' },
  { value: '6 Months', label: '6 Months' },
  { value: 'Ongoing', label: 'Ongoing' },
  { value: 'Last Minute Hire', label: 'Last Minute Hire' },
];

export const CLIENT_HOW_HEARD_ABOUT_US_OPTIONS = [
  { label: 'You reached out to me', value: 'You reached out to me' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Social Media', value: 'Social Media' },
  { label: 'Networking Event', value: 'Networking Event' },
  { label: "I'm a previous candidate", value: "I'm a previous candidate" },
  { label: "I'm a returning client", value: "I'm a returning client" },
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Web Search', value: 'Web Search' },
  { label: 'Yelp', value: 'Yelp' },
  { label: 'I was referred', value: 'I was referred' },
  { label: 'Other', value: 'Other' },
];

export const CANDIDATE_HOW_HEARD_ABOUT_US_OPTIONS = [
  { label: 'CraigsList', value: 'CraigsList' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Glassdoor', value: 'Glassdoor' },
  { label: 'Indeed', value: 'Indeed' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Mogul', value: 'Mogul' },
  { label: 'Monster', value: 'Monster' },
  { label: 'Networking Event', value: 'Networking Event' },
  { label: 'My Employer', value: 'My Employer' },
  { label: 'Email Marketing', value: 'Email Marketing' },
  { label: 'Referral', value: 'Referral' },
  {
    label: 'Recruiter reached out to me',
    value: 'Recruiter reached out to me',
  },
  { label: 'Twitter', value: 'Twitter' },
  { label: 'University Job Board', value: 'University Job Board' },
  { label: 'Web Search', value: 'Web Search' },
  { label: 'Yelp', value: 'Yelp' },
  { label: 'ZipRecruiter', value: 'ZipRecruiter' },
  { label: 'Other', value: 'Other' },
];

export const CLIENT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  PARTIAL_ACTIVE: 2,
  NEW: 3,
  INVITED: 4,
};

export const GOOGLE_MAPS = {
  DEFAULT_LOCATION: { lat: 37.733795, lng: -122.446747 },
  DEFAULT_ZOOM: 10,
  DEFAULT_RADIUS: 10,
};

export const JOB_CANDIDATE_STATUS = {
  PENDING: 1,
  DROPPED: 2,
  SCHEDULED_FOR_INTERVIEW: 3,
  OFFER_MADE: 4,
  HOLD: 5,
  INTERVIEWED: 6,
  FOLLOW_UP_INTERVIEW: 3,
};

export const JOB_CANDIDATE_STATUS_TITLE = {
  PENDING: 'Pending Candidate <br/> Response',
  DROPPED: 'Dropped',
  SCHEDULED_FOR_INTERVIEW: 'Scheduling',
  OFFER_MADE: 'Offer Made',
  INTERVIEWED: 'Interviewed',
};

export const JOB_CANDIDATE_SCHEDULING_SUB_STATUS = {
  DEFAULT: 0,
  SCHEDULED: 1,
  CONFLICT: 2,
  INTERVIEW_TIME_UP: 3,
};

export const JOB_CANDIDATE_SCHEDULING_SUB_STATUS_TITLE = {
  DEFAULT: 'Scheduling',
  SCHEDULED: 'Scheduled',
  CONFLICT:
    // eslint-disable-next-line max-len
    'There is a scheduling conflict. Please give more availability on your calendar. An email has also been sent to the candidate requesting additional availability.',
  INTERVIEW_TIME_UP: 'Interview Time Up',
};

export const CANDIDATE_ON_PERMANENT_JOB =
  "Looks like you're on an assignment or a permanent job right now! Please reach out to your Candidate Success Manager.";

export const GENDER_PRONOUN_OPTIONS = [
  { value: 'She, Her, Hers', label: 'She, Her, Hers' },
  { value: 'He, Him, His', label: 'He, Him, His' },
  { value: 'Ze, Zir, Zirs', label: 'Ze, Zir, Zirs' },
  { value: 'They, Them, Theirs', label: 'They, Them, Theirs' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const timezoneOptions = [
  {
    label: 'EST',
    value: 'America/New_York',
  },
  {
    label: 'CST',
    value: 'America/Chicago',
  },
  {
    label: 'MST',
    value: 'America/Denver',
  },
  {
    label: 'PST',
    value: 'America/Los_Angeles',
  },
  {
    label: 'AKST',
    value: 'America/Anchorage',
  },
  {
    label: 'HST',
    value: 'Pacific/Honolulu',
  },
];
export const reasonForLeavingOptions = [
  {
    label: 'Currently employed here',
    value: 'Currently employed here',
  },
  {
    label: 'Promotion/moved to a new role',
    value: 'Promotion/moved to a new role',
  },
  { label: 'Seeking growth', value: 'Seeking growth' },
  { label: 'Work/life balance', value: 'Work/life balance' },
  {
    label: 'Personal reasons/sabbatical',
    value: 'Personal reasons/sabbatical',
  },
  {
    label: 'Hours/schedule change',
    value: 'Hours/schedule change',
  },
  {
    label: 'Job responsibilities shift',
    value: 'Job responsibilities shift',
  },
  {
    label: 'Job didn’t match expectations',
    value: 'Job didn’t match expectations',
  },
  { label: 'Relocation', value: 'Relocation' },
  { label: 'Compensation', value: 'Compensation' },
  {
    label: 'Management/leadership changes',
    value: 'Management/leadership changes',
  },
  {
    label: 'Layoff/role eliminated',
    value: 'Layoff/role eliminated',
  },
  { label: 'Temporary job/project', value: 'Temporary job/project' },
  { label: 'Terminated', value: 'Terminated' },
];

export const JOB_TYPE_OPTIONS = {
  CONTRACT: 'Contract/Temporary',
  TEMPTOHIRE: 'Temp to Hire',
  PERMANENT: 'Permanent',
};
