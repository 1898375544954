import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../pr-loading';
import PrTooltipComponent from '../pr-tooltip';
import './pr-button.scss';

function PrButton({
  className,
  value,
  onClick,
  type,
  isDisabled,
  isLoading,
  icon,
  html,
  tooltip = '',
}) {
  return (
    <div
      className={`pr-button-wrapper ${type || ''} ${
        isDisabled ? 'disable' : ''
      } ${isLoading ? 'loading' : ''} ${className || ''}`}
      onClick={(e) => onClick && onClick(e)}
    >
      {isLoading && <Loading type="button" color="#fff" />}
      {!isLoading && icon ? <img src={icon} alt="" /> : null}
      {!isLoading && <p>{value}</p>}
      {!isLoading && html && html}
      {!isLoading && type.includes('withIcon') ? (
        <div className="tooltip-wrap">
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4953 18.3124L11.6327 18.3124L13.4954 12.0101L11.6328 12.0101"
              stroke="#4A85FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5581 7.50839H13.5716"
              stroke="#4A85FB"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <PrTooltipComponent
            type="with-triangle large top-right black"
            CTAsList={[{ type: 'description', name: tooltip }]}
          />
        </div>
      ) : null}
    </div>
  );
}

PrButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  html: PropTypes.node,
  tooltip: PropTypes.string,
};

PrButton.defaultProps = {
  onClick: undefined,
  type: '',
  isLoading: false,
  isDisabled: false,
  icon: null,
  className: '',
  html: null,
  tooltip: '',
};

export default PrButton;
