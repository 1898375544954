import React, { Component } from 'react';
import './footer.scss';
import packageJson from '../../../package.json';

export default class Footer extends Component {
  render() {
    const current_year = new Date().getFullYear();
    return (
      <div className="page-footer">
        <p>Version {packageJson.version}</p>
      </div>
    );
  }
}
