import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Popup,
  PopupHeader,
  PopupFooter,
  PopupBody,
} from '../../components/--primitives/pr-popup';
import {
  strictValidStringWithMinLength,
  loadRoute,
} from '../../utils/commonUtils';
import PrButton from '../../components/--primitives/pr-button';
import { flush } from '../../redux/actions/error.actions';
import './error-popup.scss';

const blacklistMessages = [
  'The user field is required.',
  'Failed to authenticate token.',
  'Your session has expired. Please login again to continue.',
  'Please login with your credentials.',
];

const ErrorPopup = ({
  message,
  callFLushAPI,
  dispatch,
  status,
  tenantConfig,
}) => {
  const isValidPopup = blacklistMessages.indexOf(message) === -1;
  return isValidPopup ? (
    <Popup
      className="error-popup"
      action="error"
      isOpen={!!status || strictValidStringWithMinLength(message)}
    >
      <PopupHeader action="error" close toggle={callFLushAPI} />
      <PopupBody>
        {status ? (
          <Fragment>
            <div className="content title">
              Your credentials do not match any candidate records. Please try
              again.
            </div>
            <div className="content text">
              If you’re an employer, please login through the employer portal.
            </div>
          </Fragment>
        ) : (
          <div
            className="content title"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </PopupBody>
      <PopupFooter align={status ? 'center' : ''}>
        {status ? (
          <Fragment>
            <PrButton
              value="Login as Employer"
              type="white tall"
              onClick={() => {
                window.location = tenantConfig.client_domain+'/login';
              }}
            />
            <PrButton
              value="Try again"
              type="blue tall"
              onClick={callFLushAPI}
            />
          </Fragment>
        ) : (
          <PrButton
            value={
              message ===
              'This activation link is expired or has already been used.'
                ? 'Go back to login'
                : 'Ok'
            }
            type="blue tall"
            onClick={
              message ===
              'This activation link is expired or has already been used.'
                ? () => {
                    loadRoute(dispatch);
                    callFLushAPI();
                  }
                : callFLushAPI
            }
          />
        )}
      </PopupFooter>
    </Popup>
  ) : (
    <React.Fragment />
  );
};

ErrorPopup.propTypes = {
  callFLushAPI: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  tenantConfig: PropTypes.object,
};

ErrorPopup.defaultProps = {
  message: null,
  status: false,
  tenantConfig: {},
};

const mapStateProps = (state) => {
  return {
    message: state.get('error').get('message'),
    status: state.get('error').get('status'),
    tenantConfig: state.get('config').get('tenantConfig'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  callFLushAPI: (...params) => dispatch(flush(...params)),
});

export default connect(mapStateProps, mapDispatchToProps)(ErrorPopup);
