/* eslint-disable no-case-declarations */
import Immutable from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { interviewScheduleConstants } from './constants';
import { typeCastResponse } from '../../utils/commonUtils';

const initialState = Immutable.fromJS({
  isLoadInner: false,
  isLoad: false,
  message: null,
  loadErr: null,
  availability: [],
  invitations: [],
  appointments_count: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Loading
    case interviewScheduleConstants.LOAD:
      return state.set('isLoad', true).set('loadErr', null);
    case interviewScheduleConstants.LOAD_INNER:
      return state.set('isLoadInner', true).set('loadErr', null);
    case interviewScheduleConstants.LOAD_SUCCESS:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('loadErr', null)
        .set('message', typeCastResponse(action, 'message'));
    case interviewScheduleConstants.LOAD_FAIL:
      return state
        .set('isLoad', false)
        .set('isLoadInner', false)
        .set('isLoadtab', false)
        .set('message', null)
        .set('loadErr', typeCastResponse(action, 'error'));
    // Reset reducer
    case interviewScheduleConstants.RESET_MESSAGE:
      return state.set('message', null).set('loadErr', null);
    // Save job Details
    case interviewScheduleConstants.GET_APPOINTMENTS:
      return state
        .set('availability', typeCastResponse(action, 'availability', 'array'))
        .set('invitations', typeCastResponse(action, 'invitations', 'array'))
        .set(
          'appointments_count',
          typeCastResponse(action, 'appointments_count', 'number'),
        );
    case LOCATION_CHANGE:
      return state
        .set('isLoadInner', false)
        .set('isLoad', false)
        .set('message', null)
        .set('loadErr', null)
        .set('details', {});
    default:
      return state;
  }
}
