export const notificationsConstants = {
  LOAD: 'notifications/LOAD',
  LOAD_SUCCESS: 'notifications/LOAD_SUCCESS',
  LOAD_FAIL: 'notifications/LOAD_FAIL',
  // For loading notifications
  LOAD_NOTIFICATIONS: 'notifications/LOAD_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_COUNT: 'notifications/UPDATE_NOTIFICATIONS_COUNT',
  LOAD_RTN: 'notifications/LOAD_RTN',
  UPDATE_RTN: 'notifications/UPDATE_RTN',
  // Flush
  FLUSH: 'notifications/FLUSH',
  NOTIFICATION_DETAIL: 'notification/detail',
};
