export const messages = {
  DEFAULT_ERROR_MESSAGE: 'Oops!! something went wrong, please try again',
  INCOMPLETE_PROFILE:
    "Your Profile is incomplete! Please fill out each section in order to unlock Ajna's full capabilities.",
  UPDATE_ON_EDIT_PROFILE:
    'We have made some updates to the experience & responsibilities, please click here to edit your profile',
  // Account Manager
  ACCOUNT_MANAGER: {
    VALID_SUBJECT: 'Please enter a valid subject',
    VALID_MESSAGE: 'Please enter a valid message',
  },
  DUPLICATE_ACCOUNT: (name = 'This domain') =>
    `${name} is already added on your account`,
  // Validations
  VALIDATIONS: {
    REQUIRED: '* Required',
    DIFFERENT_ADDRESS: 'Please add different address.',
    FEEDBACK_REQUIRED: 'Please enter the feedback',
    AT_LEAST_ONE_INTERVIEW_TYPE_IS_REQUIRED: 'Please select the Interview Type',
    AT_LEAST_ONE_INTERVIEWER_IS_REQUIRED: 'Please add at least one Interviewer',
    STATUS_IS_REQUIRED: 'Please select the status',
    ROLE_IS_REQUIRED: 'Please select the role',
    INVALID_EMAIL_ADDRESS: 'Please enter a valid email address',
    EMAIL_ALREADY_EXISTS: 'Email address is already taken',
    EMAIL_REQUIRED_FOR_FORGOT_PASSWORD:
      'Please enter your registered email address',
    INVALID_URL: 'Please enter a valid url',
    DUPLICATE_DOMAIN: 'This domain has already been added to your account.',
    INVALID_COMPANY_NAME: 'Please enter your company name',
    INVALID_COMPANY_WEBSITE: 'Please enter your company website',
    INVALID_TITLE: 'Please enter a valid title',
    INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
    INVALID_MOBILE_NUMBER: 'Please enter a valid mobile number',
    STRICT_VALID_INTEGER: 'Must be an integer',
    INVALID_FILE_NAME: 'Please select a valid file',
    INVALID_PASSWORD_FORMAT:
      'Please enter your password as per the password requirements',
    INVALID_EXPERIENCE: 'Experience must be greater than 0 years',
    DUPLICATE_EXPERIENCE: 'Please select unique values for your experience',
    INVALID_DUTIES_LENGTH: 'Please select at least one duty',
    INVALID_EXP_DUTIES_LENGTH:
      'Please select at least one job duty that you performed during this job.',
    INVALID_ZIP_CODE: 'Please enter a valid zip code',
    INVALID_JOB_TITLE: 'Please select a job title',
    INVALID_INDUSTRY_FOR_CANDIDATE:
      'Please select the industry that you worked in',
    INVALID_INDUSTRY_FOR_CLIENT: 'Please select an industry',
    STRICT_VALID_VALUE: 'Please select a valid value',
    INVALID_SOURCE: 'Please select how you heard about us',
    INVALID_TELL_US_MORE: 'Please tell us more',
    REGION_ALREADY_EXISTS: 'This region already exists',
    INVALID_REGION_BOUNDARY_TYPE: 'Please select the type of area boundary',
    RESOURCE_IS_ALREADY_INCLUDED: 'Please select a different resource',
    AT_LEAST_ONE_BOUNDARY_IS_REQUIRED:
      'Please provide at least one area that defines boundary',
    AT_LEAST_ONE_RESOURCE_IS_REQUIRED:
      'Please select at least one resource is required',
    AT_LEAST_ONE_JOB_INTERESTS_IS_REQUIRED:
      'Please select at least one job interest is required',
    AT_LEAST_ONE_INDUSTRIES_IS_REQUIRED:
      'Please select at least one Industry interest is required',
    AT_LEAST_ONE_JOB_TYPE_IS_REQUIRED:
      'Please select at least one job type is required',
    AT_LEAST_ONE_EMPLOYMENT_TYPE_IS_REQUIRED:
      'Please select at least one employment type is required',
    INVALID_EDUCATION: 'Please select education field',
    INVALID_NAME: 'Please enter name',
    STRICT_VALID_NAME: 'Please enter a valid name',
    INVALID_ROLE: 'Please select role',
    REGION_DOES_NOT_EXIST: 'No such region exists',
    DECLINE_REASON_MANDATORY:
      'Please provide your reason for declining this job invite',
    INVALID_FIRST_NAME: 'Please enter your first name',
    INVALID_LAST_NAME: 'Please enter your last name',
    INVALID_PASSWORD: 'Please enter the password',
    INVALID_CONFIRM_PASSWORD: 'Please confirm your password',
    PASSWORD_CONF_PASS_UNMATCH: 'Passwords do not match',
    TIMESHEET_REJECT_REASON:
      'Please provide the reason for rejecting this timesheet',
  },
  // Show talent form
  SHOW_TALENT_FORM: {
    JOB_NOT_EXIST: 'This job post no longer exists',
    INACTIVE_JOB_POST: 'This job post already exists',
    INVALID_JOB_POST:
      'This job post is incomplete. Please provide all required fields to see matched talent for this job',
    INVALID_JOB_DURATIONS: 'Select your job type',
    INVALID_EMPLOYMENT_TYPE: 'Select your employment type',
    INVALID_NO_OF_POSITIONS: 'Select at least one',
  },
  TOP_MATCHES_SCREEN: {
    NO_RESULTS:
      'We aren’t finding any jobs that are a perfect match for your given search parameters <br>- try editing the parameters on the left to broaden your search',
    LESS_RESULTS:
      'Not finding the perfect fit? Try editing the parameters on the left to broaden your search.',
  },
  // Places auto complete constants
  PLACES_AUTO_COMPLETE: {
    NO_RESULTS: 'No results found',
    CITY_STATE_MANDATORY: 'Please provide your preferred city and state',
    CITY_MANDATORY: "Please enter city name in 'City, State' format",
    CITY_IS_ALREADY_INCLUDED:
      'Please select a different city, as this city is already selected.',
    CITY_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different city as this area is already included in an existing region',
    COUNTY_MANDATORY: "Please enter county name in 'County, State' format",
    COUNTY_IS_ALREADY_INCLUDED: 'Please select a different county',
    COUNTY_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different county as this county is already included in an existing region',
    STATE_MANDATORY: 'Please enter state name',
    STATE_IS_ALREADY_INCLUDED: 'Please select a different state',
    STATE_ALREADY_EXISTS_IN_OTHER_REGIONS:
      'Please select a different state as this state is already included in an existing region',
    COMPLETE_ADDRESS: 'Please provide your complete address',
    PICK_FROM_SUGGESTION_LIST:
      'Please select an address from the suggestions list',
  },
  FEEDBACK_LABEL_TEXT:
    'Your feedback is important to us and will be shared directly with the interviewer(s).',
  INTERVIEW_EXPERIENCE: 'Tell us about your interview experience',

  // Calendar constants
  CALENDAR: {
    OUT_OF_BOUNDS: 'Please provide availability for a current or future time.',
    TIME_PASSED: 'You can not remove availablity now',
    NO_EVENTS: 'Please provide at least one slot to save your availability',
    JOB_INTERVIEWS_ALREADY_SCHEDULED_IS_SELECTED:
      "You cannot add availability when 'Jobs interviews already scheduled' is selected.",
    ALL_ROUNDS_IS_SELECTED:
      "You cannot add availability when 'All rounds' is selected, please go to a particular round to add availability",
    CANNOT_ADD_AVAILABILITY_IN_SCHEDULED_TAB: {
      TEXT: 'The “Scheduled Interviews” calendar is view-only.',
      SUB_TEXT:
        'Please toggle to the “Available hours” view if you’re looking to add or edit your interview availability.',
    },
    TIME_NO_LONGER_AVAILABLE:
      'Sorry, the preferred time slot has passed! Please choose another time slot. If there are none available, select “None of these work for me"',
  },

  COMPENSATION_I_TEXT:
    'Include bonus, commission, or other compensation if relevant',

  APPLY_PROCESS_HELPER_TEXT: {
    JOB_FUNCTIONS: 'Which of the following job functions interests you ?',
    EXPERIENCE: 'What kind of work experience do you have?',
    EXPERIENCE_RESPONSIBILITIES:
      "Boost your job search by including your past work experience. Completing your profile enhances our system's ability to match you with ideal job opportunities and increases your visibility to potential employers. If you can't find an exact job title match, choose the closest option, and our recruiters can assist you in refining your profile.",
    JOB_LOCATION: 'What is your preferred job location?',
    PREF_JOB_LOCATION: 'Where would you like to work?',
    JOB_MATCHES: `Great! Here's a preview of your top matches!`,
    CANDIDATE_INFO: (tenantName) =>
      `Welcome to ${tenantName}! Please start by creating your profile. Once completed, you will be able to view job matches, express interest in positions, and directly communicate with our team. We’re excited to assist you in finding your next job opportunity!`,
    THANK_YOU_MESSAGE:
      'An activation email has been sent your way! Please activate your account to complete the registration and see your matches!',
    ACCOUNT_ALREDAY_EXISTS:
      'An account already exists with this email address.',
    JOB_SEARCH_DETAILS_SUBTEXT:
      'Tell us what you are looking for in your next opportunity',
  },
  EDIT_PROFILE: {
    GENERAL_INFO:
      'Make changes and updates to your information, then click the "Save and Next" button',
    JOB_SEARCH_DETAILS_SUBTEXT:
      'Update your job search details, then click the "Save and Next" button',
    EXPERIENCE_RESPONSIBILITIES:
      ' Update your previous work experience, then click the "Save and Next" button',
    RESUME:
      'Upload any relevant resumes below to detail your professional history, experience, and background, then press the “Submit” button',
  },
  WRONG_URL:
    'That link could not be found. We are bringing you to the Dashboard.',
  NOT_ACTIVELY_LOOKING_LIKE_JOB:
    'Thank you for "Liking" this job! Your "Actively Looking” toggle is currently set to OFF. To show your interest in a job, and be considered among other candidates who also "Like" this job, please turn your "Actively Looking" toggle ON in your Profile Settings.',
};
