import React from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import moment from 'moment-timezone';
import {
  VALID_URL,
  VALID_US_ZIP,
  VALID_LARGE_NUMBER,
  FIRST_CHARACTER_IN_WORDS,
  NUMBER_UPTO_ONE_DECIMAL,
  NUMBER_UPTO_TWO_DECIMAL,
} from './regexs';

/**
 * Checks if a valid string;
 * @param val: number/string/object/array != (undefined or null)
 */
export const validValue = (val) =>
  typeof val !== 'undefined' && val !== undefined && val !== null;

/**
 * Get window width and height
 */
export const getWindowDimensions = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: x,
    height: y,
  };
};

/**
 * get element offset (width & height)
 * @param el: object
 */
export const getElementOffset = (el) => {
  let _x = 0;
  let _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
};

/**
 * Serailize json to query string
 * @param {*} object
 */
export const jsonToQueryString = (obj) =>
  strictValidObject(obj) &&
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

/**
 * Checks if a valid string
 * @param str: string
 */
export const strictValidString = (str) => !!str && typeof str === 'string';

/**
 * Checks if a valid string and validate with min length.
 * @param str: string
 */
export const strictValidStringWithMinLength = (str, length = 1) =>
  !!str && typeof str === 'string' && str.length >= length;

/**
 * Checks if a valid string which when split with a delimeter will give an array with specified minimum length
 * @param str: string
 * @param delimeter: string
 * @param minLength: integer
 */
export const strictValidSplittableStringWithMinLength = (
  str,
  delimeter,
  minLength,
) =>
  strictValidString(str) &&
  strictValidArrayWithMinLength(str.split(delimeter), minLength);

/**
 * Typecast or converts forcefully a string, an object or an array to string else returns null
 * @param str: string
 */
export const typeCastToString = (str) =>
  (!!str &&
    ((strictValidString(str) && str) ||
      str.toString() ||
      JSON.stringify(str))) ||
  '';

/**
 * Capitalizes the first letter of every word in string
 * @param str: string
 */
export const capitalizeFirstLetter = (str) =>
  (strictValidString(str) &&
    str.replace(FIRST_CHARACTER_IN_WORDS, (l) => l.toUpperCase())) ||
  null;

/**
 * Capitalizes the first letter of every word in string but not word after apostrophe
 * @param str: string
 */
export const titleCase = (str = '') => {
  if (!strictValidString(str)) return null;
  const strArr = str.toLowerCase().split(' ');
  for (let i = 0; i < strArr.length; i++) {
    strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
  }
  return strArr.join(' ');
};

/**
 * Get name & extension from fileName
 * @param fileName: string
 */
export const getFileNameAndExtension = (fileName) =>
  (strictValidString(fileName) &&
    strictValidSplittableStringWithMinLength(fileName, '.', 2) && {
      name: fileName.split('.')[0],
      ext: fileName.split('.')[1],
    }) ||
  {};

export const validAlert = (alert) =>
  validObjectWithParameterKeys(alert, ['message', 'type']) &&
  validValue(alert.message) &&
  validValue(alert.type);

/**
 * Checks if given value is strictly a number
 * @param num: number
 */
export const strictValidNumber = (num) =>
  validValue(num) && typeof num === 'number' && !isNaN(num);

/**
 * Checks if a valid array
 * @param arr: array
 */
export const strictValidArray = (arr) => arr && Array.isArray(arr);

/**
 * Checks if a valid array with minimum specified length
 * @param arr: array
 * @param minLength: integer
 */
export const strictValidArrayWithMinLength = (arr, minLength) =>
  strictValidArray(arr) && arr.length >= minLength;

/**
 * Checks if a valid array with length
 * @param arr: array
 */
export const strictValidArrayWithLength = (arr) =>
  strictValidArray(arr) && !!arr.length;

/**
 * Checks if a valid object
 * @param obj: object
 */
export const strictValidObject = (obj) =>
  obj &&
  obj === Object(obj) &&
  Object.prototype.toString.call(obj) !== '[object Array]';

/**
 * Checks if a valid object with keys
 * @param obj: object
 */
export const strictValidObjectWithKeys = (obj) =>
  strictValidObject(obj) && !!Object.keys(obj).length;

/**
 * Checks if a valid object with specified keys
 * @param obj: object
 * @param parameterKeys: array
 */
export const validObjectWithParameterKeys = (obj, parameterKeys = []) =>
  strictValidObjectWithKeys(obj) &&
  strictValidArrayWithLength(parameterKeys) &&
  Object.keys(obj).filter((k) => parameterKeys.indexOf(k) > -1).length ===
    parameterKeys.length;

/**
 * Generates a regular expression from a given list of regular expressions
 * @param regExpList: array of regular expression strings
 */
export const concatenateRegularExpressions = (regExpList = []) => {
  let regExp = new RegExp();
  if (strictValidArrayWithLength(regExpList)) {
    try {
      regExp = new RegExp(regExpList.join(''));
    } catch (error) {
      // Do nothing
    }
  }
  return regExp;
};

/**
 * Gets file extension
 * @param fileName: string
 */
export const getFileExtension = (fileName) =>
  (strictValidString(fileName) &&
    fileName.substring(fileName.lastIndexOf('.'), fileName.length)) ||
  '';

/**
 * Typecasts a key value pair (k, v) to string and appends it to or appends a specified string value to it
 * based on appendAfter boolean variable
 * @param k: string
 * @param v: string
 * @param appendString: string
 * @param appendAfter: boolean
 */
export const addKeyValuePairAsString = (
  k,
  v,
  appendString = '',
  appendAfter = true,
) => {
  let str = '';
  if (!appendAfter) {
    str += typeCastToString(appendString);
  }
  if (validValue(v)) {
    if (['string', 'number', 'boolean'].indexOf(typeof v) > -1) {
      str = `${k}: ${typeCastToString(v)}`;
    } else if (strictValidArrayWithLength(v)) {
      str = `${k}: [${v.join(', ')}]`;
    } else {
      str = `${k}: [${JSON.stringify(v)}]`;
    }
  } else {
    str = `${k}: `;
  }
  if (appendAfter) {
    str += typeCastToString(appendString);
  }
  return str;
};

/**
 * Typecasts an immutable reducer object to its actual values
 * @param immutableObject: object
 */
export const typeCastToKeyValueObject = (immutableObject) =>
  (strictValidObject(immutableObject) &&
    immutableObject instanceof Map &&
    immutableObject.toJSON()) ||
  (strictValidObject(immutableObject) &&
    validObjectWithParameterKeys(immutableObject, ['size', '_root']) &&
    immutableObject.toJSON()) ||
  (strictValidObject(immutableObject) && immutableObject) ||
  (!strictValidObject(immutableObject) &&
    validValue(immutableObject) &&
    immutableObject);

/**
 * Imports all files in a directory
 * @param importedObj: object
 */
export const importImagesFromImageDirectory = (importedObj) => {
  const filesObj = {};
  importedObj.keys().forEach((file) => {
    filesObj[file.replace('./', '')] = importedObj(file);
  });
  return filesObj;
};

/**
 * Loads a route
 * @param dispatch: function
 * @param route: string
 */
export const loadRoute = (dispatch, route) => {
  if (route) {
    dispatch(push(route));
    return;
  }
  dispatch(push('/'));
};

/**
 * Remove null or undefined key value pairs from an object
 * @param obj: object
 * @param removeEmptyArray: bool
 */
export const removeInValidKeyValuePairs = (obj, removeEmptyArray) => {
  const res = {};
  if (strictValidObjectWithKeys(obj)) {
    Object.values(obj).forEach((v, k) => {
      if (
        (validValue(v) && !strictValidArray(v)) ||
        strictValidArrayWithLength(v) ||
        (removeEmptyArray && strictValidArrayWithLength(v))
      ) {
        res[Object.keys(obj)[k]] = v;
      }
    });
  }
  return res;
};

/**
 * Is valid url
 * @param url: string
 */
export const isURLValid = (url) =>
  strictValidString(url) && VALID_URL.test(url);

/**
 * Is valid zip
 * @param zip: string
 */
export const isZipValid = (zip) =>
  strictValidString(zip) && VALID_US_ZIP.test(zip);

/**
 * Get random color
 * @param opacity: string
 */
export const randomBGColor = () => {
  const colors = [
    'RED',
    'GREEN',
    'BLUE',
    'DARKSLATEGRAY',
    'MAROON',
    'GRAY',
    'TEAL',
    'PURPLE',
    'MEDIUMVIOLETRED',
    'INDIGO',
    'DARKSLATEBLUE',
    'BROWN',
    'PURPLE',
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

/**
 * Formatting number for thousand seperator
 */
export const formatNumber = (num) =>
  strictValidNumber(num) && num.toString().replace(VALID_LARGE_NUMBER, '$1,');

/**
 * Formatting number for thousand seperator
 *
 */
export const formatNumberWithCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

/**
 * Converts integer to double digit example 9 -> 09, 12 -> 12, 992 -> 992
 * @param integer: string
 */
export const integerToDoubleDigit = (integer) =>
  (strictValidNumber(integer) && `0${integer}`.slice(-2)) || '00';

/**
 * Add http to the url;
 * @param integer: string
 */
export const addhttp = (url) => {
  let updatedURL;
  if (strictValidString(url) && !/^(?:f|ht)tps?\:\/\//.test(url)) {
    updatedURL = `http://${url}`;
  }
  return updatedURL;
};

/**
 * Checks if given value is function
 * @param fn: function
 */
export const isFunction = (fn) => validValue(fn) && typeof fn === 'function';

/**
 * Extract file name from aws s3 path
 * @param url: string
 */
export const extractFileName = (url) => {
  const delimeter = '_';
  let response = (strictValidString(url) && url.split('/').pop()) || '';
  let fileNameArr = [];
  if (strictValidSplittableStringWithMinLength(response, delimeter, 1)) {
    fileNameArr = response.split(delimeter);
    fileNameArr.shift();
    response = fileNameArr.join(delimeter);
  }
  return response;
};

export const getHours = (hours) => {
  return hours > 1 ? `${hours} Hrs` : `${hours} Hr`;
};

export const getIntegerValue = (val) => Math.round(val);

/**
 * Round off an Integer to 2 decimal places
 * @param value: Integer
 */
export const roundOffTo2Decimals = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

/**
 * Normalize a phone number
 * @param value: String
 */
export const normalizePhone = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7)
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const deNormalizePhone = (value) =>
  value ? value.replace(/[^\d]/g, '').slice(0, 10) : value;

export const createNavigationLink = (
  link,
  value,
  willOpenInBlankPage = false,
) => {
  return willOpenInBlankPage ? (
    <Link target="_blank" to={link}>
      {value}
    </Link>
  ) : (
    <Link to={link}>{value}</Link>
  );
};

/**
 * Note - This will return number with upto one decimal without round Off
 *
 * function('34.567') = '34.5'
 *
 * @param {value}: Number or(string) eg 8 or '8'
 */

export const uptoOneDecimal = (value = '') => {
  if (!NUMBER_UPTO_ONE_DECIMAL.test(value) && strictValidString(value)) {
    const index = value.indexOf('.');
    return value.substring(0, index + 2);
  }
  return value ? parseFloat(value) : value;
};

export const uptoTwoDecimal = (value = '') => {
  let val = value;
  if (!NUMBER_UPTO_TWO_DECIMAL.test(value) && strictValidString(value)) {
    const index = value.indexOf('.');
    val = value.substring(0, index + 3);
  }
  return val ? parseFloat(val).toFixed(2) : val;
};

/**
 * Scroll modal to top
 */
export const scrollModalToTop = () => {
  const modal = document.getElementsByClassName('modal');
  if (modal.length) {
    modal[0].scrollTop = 0;
  }
};

/**
 * Typecast response from api to specified type, default string
 * @param object: string or object containing key: string
 * @param key: string in object
 * @param type: string
 * @param defaultValue: any
 */
export const typeCastResponse = (
  object,
  key,
  type = 'string',
  defaultValue = null,
) => {
  let response = null;
  switch (type) {
    case 'number':
      response =
        (validObjectWithParameterKeys(object, [key]) && Number(object[key])) ||
        defaultValue ||
        0;
      break;
    case 'string':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          typeCastToString(object[key])) ||
        defaultValue ||
        null;
      break;
    case 'object':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          strictValidObject(object[key]) &&
          object[key]) ||
        defaultValue ||
        {};
      break;
    case 'array':
      response =
        (validObjectWithParameterKeys(object, [key]) &&
          strictValidArray(object[key]) &&
          object[key]) ||
        defaultValue ||
        [];
      break;
    default:
      break;
  }
  return response;
};

/**
 * format date in the given format
 */
export const formatDate = (date, format = 'MM/DD/YYYY') => {
  return moment(date).format(format);
};

/**
 * This function convert Mins To Hrs (Hrs:Mins) even given parameter is nagative.
 * e.g (m = -210) -> (-3:30)
 * @param min: Number
 */
export const convertMinsToHrsMins = (m) => {
  const min = m && Math.round(m);
  let hrs = Math.floor(min / 60);
  hrs += hrs < 0 ? 1 : 0;
  let min2 = Math.round(Math.abs(min % 60));
  min2 = min2 < 10 ? `0${min2}` : min2;
  return `${hrs}:${min2}`;
};

export const decimalNoToHours = (value) => {
  if (!value) return '0:00';
  return convertMinsToHrsMins(value * 60);
};

export const decimalNoToDollers = (value) => {
  return `$${uptoTwoDecimal(value)}`;
};

/**
 * print address
 * @param {*} str
 * @param {*} length
 */
export const fullAddress = (address = {}) => {
  let {
    location = '',
    address_2 = '',
    city = '',
    state = '',
    zipcode = '',
  } = address;
  location =
    (strictValidStringWithMinLength(location, 1) && `${location}` + ', ') || '';
  address_2 =
    (strictValidStringWithMinLength(address_2, 1) && `${address_2}` + ', ') ||
    '';
  city = (strictValidStringWithMinLength(city, 1) && `${city}` + ', ') || '';
  state = (strictValidStringWithMinLength(state, 1) && `${state}`) || '';
  zipcode =
    (strictValidStringWithMinLength(zipcode, 1) && ', ' + `${zipcode}`) || '';
  return `${location}${address_2}${city}${state}${zipcode}`;
};

export const validIndustry = (industry) =>
  validObjectWithParameterKeys(industry, ['_id', 'title']) &&
  strictValidString(industry.title);

export const mapCounts = (count) => {
  switch (count) {
    case 1:
      return 'ONE';
    case 2:
      return 'TWO';
    case 3:
      return 'THREE';
    case 4:
      return 'FOUR';
    case 5:
      return 'FIVE';
    case 6:
      return 'SIX';
    case 7:
      return 'SEVEN';
    case 8:
      return 'EIGHT';
    case 9:
      return 'NINE';
    case 10:
      return 'TEN';
    default:
  }
};

export const deepCopy = (srcObj) => {
  const outObj = Array.isArray(srcObj) ? [] : {};
  if (typeof srcObj !== 'object' || srcObj === null) {
    return srcObj;
  }
  for (const key in srcObj) {
    outObj[key] = deepCopy(srcObj[key]);
  }
  return outObj;
};

/**
 * Convert minutes to hours and minutes
 */
export function toHoursAndMinutes(mins) {
  if (isNaN(mins) || mins === 0) return '-';

  const hours = Math.floor(mins / 60);
  const minutes = Math.floor(mins % 60);

  let hoursText;
  let minutesText;

  if (hours === 0) {
    hoursText = '';
  } else if (hours === 1) {
    hoursText = `${hours} hour`;
  } else {
    hoursText = `${hours} hours`;
  }

  if (minutes === 0) {
    minutesText = '';
  } else if (minutes === 1) {
    minutesText = `${minutes} minute`;
  } else {
    minutesText = `${minutes} minutes`;
  }

  if (hours === 0 && minutes > 0) {
    return minutesText;
  }

  if (hours > 0 && minutes === 0) {
    return hoursText;
  }

  return `${hoursText} and ${minutesText}`;
}

export const getFirstName = (name) =>
  strictValidString(name) ? name.trim().split(' ')[0] : '';

export const getConditionalName = (firstName, middleName, lastName, name) => {
  if (
    strictValidString(firstName) &&
    strictValidString(middleName) &&
    strictValidString(lastName)
  )
    return `${firstName.trim().charAt(0).toUpperCase()}${middleName
      .trim()
      .charAt(0)
      .toUpperCase()}${lastName.trim().charAt(0).toUpperCase()}`;
  if (strictValidString(firstName) && strictValidString(lastName))
    return `${firstName.trim().charAt(0).toUpperCase()}${lastName
      .trim()
      .charAt(0)
      .toUpperCase()}`;
  return getFirstName(name);
};

export const checkSourceVal = (val) =>
  val === 'Other' ||
  val === 'Networking Event' ||
  val === 'Referral' ||
  val === 'Recruiter reached out to me';

/**
 * convert Experience into months and years i.e 1.5 converted to 1 year 6 months
 * @param exp: Number
 */

export const convertExperinceToMonthYear = (exp) => {
  let years = 0;
  let months = 0;
  const experience = parseFloat(exp);
  if (strictValidNumber(experience) && !isNaN(experience)) {
    const totalMonths = Math.trunc(Math.round(experience * 12));
    years = Math.trunc(experience);
    months = totalMonths - 12 * years;
  }
  return {
    years,
    months,
  };
};

export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
// deformat currency string to proper number
export const formatStrictNumber = (number) =>
  Number(number.replace(/[^0-9.-]+/g, ''));

/**
 * Checks if value boolean
 * @param val: boolean
 */
export const isBoolean = (val) => typeof val === 'boolean';

/**
 * Check user image if dummy or not
 * @param {*} url
 * @returns
 */
export const checkUserImage = (url) => {
  if (strictValidString(url)) {
    return url.search('users/user.png') > -1 ? '' : url;
  }
  return false;
};

export const createDropdownOption = ({
  arr = [],
  value = '_id',
  label = 'title',
  status = false,
}) => {
  let arrVal = [];
  if (status) {
    arrVal = arr.filter((v) =>
      validObjectWithParameterKeys(v, ['status']) ? v.status : v,
    );
  } else arrVal = arr;
  return strictValidArrayWithLength(arrVal)
    ? arrVal.map((v) => ({
        value: v[value],
        label: v[label] !== undefined ? v[label].toString() : '',
        ...v,
      }))
    : [];
};
