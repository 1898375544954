import moment from 'moment';
import { validObjectWithParameterKeys } from '../../utils/commonUtils';

/**
 * To get place time zone on the basis of lat lng
 * Access role allowed - company aka client
 * @param latLng: object
 */
export const getPlaceTimeZone = (latLng) => async (dispatch, getState, api) => {
  try {
    // Put saved job with api
    const url =
      `${process.env.REACT_APP_APIHOST}/settings/timezone?` +
      `latitude=${latLng.lat}&longitude=${
        latLng.lng
      }&timestamp=${moment().unix()}`;
    const res = await api.get(url);
    if (!validObjectWithParameterKeys(res, ['timeZoneId'])) {
      return false;
    }
    return res.timeZoneId;
  } catch (error) {
    // If an error occurs, set error field
    return [];
  }
};
